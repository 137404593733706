import React from "react"

import { CarouselScroller } from "../components/carousel-scroller"
import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"Website"}
    titleSecond={"Design"}
    subtitle={"Functional Design"}
    content={
      " We create custom and all device responsive Website Design that result in higher conversion within quick turn-around time-frames."
    }
    bgpurple={false}
    image={require("./../assets/web-desing-main.svg")}
  />
)
const HowWeDoIt = () => {
  const data = [
    {
      title: "Identify",
      content:
        "We think first like you.This will help us in understanding your brand, needs, business objectives and goals for your website, and developing an appropriate timeline and project plan.",
    },
    {
      title: "Research",
      content:
        "We dive in and research extensively on your industry, competitors, audience, and interview internal stakeholders to construct a tailored strategy and design.",
    },
    {
      title: "Content Strategy",
      content:
        "Working hand in gloves with your team, we ascertain what content is necessary and of great value for your website as well as where it should be placed for maximum impact.",
    },
    {
      title: "Design",
      content:
        "We style the graphics and visual parts required to bring your web site to life.",
    },
  ]
  return <CustomHowWeDoIt data={data} />
}
const ShowCase = () => {
  const data = [
    require("../assets/touchcore-rj4-website-Design.png"),
    require("../assets/touchcore-website-design.png"),
    require("../assets/sarolifecare-touchcore.png"),
    require("../assets/touchcore-rj4-website-Design.png"),
  ]
  return (
    <section className="container">
      <CarouselScroller>
        <div className="grid grid-cols-2 gap-5 w-8/12 mx-auto">
          {data.map(v => (
            <img src={v} alt="showcasing works" />
          ))}
        </div>
      </CarouselScroller>
    </section>
  )
}
const WebDesign = () => (
  <Layout insightCategory="web-design">
    <SEO
      title="Web Design Company in Lagos Nigeria | Touchcore"
      description="Web design Nigeria - We are Nigeria's top web design company, located in Lagos. We offer e-Commerce, SEO, Digital Marketing and Branding services."
    />
    <HeroSection />
    <ShowCase />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)

export default WebDesign
